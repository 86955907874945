<template>
  <div>
    <BaseHeader :title="'Checkout'"></BaseHeader>
    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="is_loading"
    ></BaseLoading>

    <div v-else class="card mt-2 border-0 text-left p-3">
      <div class="row">
        <div class="col-12 col-sm-8 py-3">
          <div
            v-for="({ id, title, price }, index) in checkoutSummary.orders"
            :key="index"
            class="
              d-flex
              flex-row
              justify-content-between
              align-items-baseline
              border-bottom-1
              py-2
            "
            style="border-color: rgb(228 228 228) !important"
          >
            <div>
              <h4 class="font-weight-bold mb-1">
                <router-link :to="{ name: 'Order', params: { id: id } }"
                  >#{{ id || "" }}</router-link
                >
              </h4>
              <p class="mt-1">{{ title || "" }}</p>
            </div>
            <h6>${{ price || "" }}</h6>
          </div>

          <div
            class="
              d-flex
              flex-row
              justify-content-between
              align-items-center
              py-2
            "
          >
            <p class="my-1">
              <router-link :to="{ name: 'PlaceOrder' }"
                ><span class="icon-chevron-left"></span> Add Orders</router-link
              >
            </p>

            <div class="my-1 d-flex flex-row align-items-center">
              <small>Subtotal</small>
              <h1 class="font-weight-black ml-1 my-1">
                ${{ checkoutSummary.total || "0.0" }}
              </h1>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-4">
          <div
            class="card bg-dark shadow py-3"
            style="background: #3e3f45 !important; box-shadow: 3px 3px 41px 6px rgb(0 0 0 / 28%) !important; max-width: 280px"
          >
            <div class="card-body">
              <h5 class="font-weight-bold">Card Checkout</h5>
              <span v-html="checkoutSummary.message"></span>
              <div
                class="
                  d-flex
                  flex-row
                  justify-content-between
                  align-content-center
                "
              >
                <img
                  class="d-sm-inline"
                  width="40%"
                  src="../../assets/global_assets/images/visa.png"
                  alt="Credit and debit card"
                />
                <img
                  width="40%"
                  class="d-sm-inline"
                  src="../../assets/global_assets/images/mastercard.png"
                  alt="Credit and debit card"
                />
              </div>
              <div
                class="
                  my-1
                  d-flex
                  flex-row
                  justify-content-between
                  align-items-baseline
                "
              >
                <small>No of Orders:</small>
                <h3 class="font-weight-black ml-1 my-1">
                  {{ checkoutSummary.orders.length || "0" }}
                </h3>
              </div>
              <div
                class="
                  my-1
                  d-flex
                  flex-row
                  justify-content-between
                  align-items-baseline
                "
              >
                <small>Subtotal:</small>
                <h3 class="font-weight-black ml-1 my-1">
                  ${{ checkoutSummary.total || "0.0" }}
                </h3>
              </div>
              <a
                class="btn btn-primary btn-block mt-2 btn-lg py-2"
                :href="checkoutSummary.checkout"
                >Check Out</a
              >
              <!-- <button
                class="btn btn-success btn-block btn-lg mt-2 mb-1"
                @click="walletPaymentUrl"
              >
                Pay From Wallet
              </button> -->
              <button
                class="btn btn-link btn-block btn-lg"
                @click="getPaymentUrl"
              >
                Refresh Payment Token
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http.index";

export default {
  name: "BulkCheckout",
  computed: {},
  data() {
    return {
      is_loading: false,
      checkoutSummary: {},
    };
  },

  methods: {
    getPaymentUrl() {
      this.is_loading = true;
      let ids = this.$route.params.ids;
      ids = JSON.parse(ids);
      if (ids) {
        http
          .post("/Checkout/BulkCheckout", {
            order_ids: ids,
            call_back: document.location.href,
          })
          .then((res) => {
            console.log(res);
            this.checkoutSummary = res.data.Data;
          })
          .catch((err) => {
            console.log("err", err);
          })
          .finally(() => {
            this.is_loading = false;
          });
      }
    },
  },
  mounted() {
    this.getPaymentUrl();
  },
};
</script>

<style>
</style>